import React from "react"
// import { Link } from "gatsby"
// import classNames from "classnames"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Banner from "../components/banner"
import data from "../json/advance.json"


const AdvancePage = function () {

  return (
    <Layout cur='/advance/' stickyHeader>
      <SEO
        title="酷课场地预订系统的优势"
        keywords="预约提醒,会员端场地预订,私教课预约,酷课订场优势,"
        description="使用酷课场地预订系统可以快速创建属于你自己的微信小程序，并借助微信公众号和小程序的社交营销体系，帮助你快速拓客；酷课场地预订系统采用公众号模版消息通知，方便、直达、无费用；酷课系统使用微信支付收款，打通线上线下闭环；灵活的日租收费模式，专享7天12小时酷课客服，永久免费升级维护，让你省钱省心。"
      />

      {
        data.map((v, i) => (
          <Banner key={i} v={v} i={i} h1 />
        ))
      }
    </Layout>
  )
}

export default AdvancePage
